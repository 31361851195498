import React from 'react'
import {Routes, Route } from 'react-router-dom'
import Home from './components/Home';
import OurStory from './routes/OurStory';
import Faq from './routes/Faq';
import Contact from './routes/Contact';
import Chef from './routes/Chef';
import Privacy from './components/Privacy';
import Terms from './components/Terms';

function App() {
  return (
    <>
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/ourstory' element={<OurStory />} />
      <Route path='/foodsafety' element={<Faq />} />
      <Route path='/contact' element={<Contact />} />
      <Route path='/chef' element={<Chef />} />
      <Route path='/privacy' element={<Privacy />} />
      <Route path='/termsofservice' element={<Terms />} />
    </Routes>
    
    </>
  );
}


export default App;
