import React from 'react'
import { Link } from 'react-router-dom'
import './FooterStyles.css'
import { FaFacebook, FaInstagram, FaTiktok, FaYoutube, FaGooglePlay, FaAppStore } from 'react-icons/fa'

const Footer = () => {
  return (
    <div className='footer'>
        <div className='container'>
            <div className='col company'>
                <h6>About</h6>
                <ul>
                    <li><Link to='/ourstory' className='link'>Our Story</Link></li>
                    <li><Link to='/chef' className='link'>Become A Chef</Link></li>
                    <li><Link to='/foodsafety' className='link'>Food Safety</Link></li>
                </ul>
            </div>
            <div className='col legal'>
                <h6>Support</h6>
                <ul>
                    <li><Link to='/privacy' className='link'>Privacy</Link></li>
                    <li><Link to='/termsofservice' className='link'>Terms of Service</Link></li>
                </ul>
            </div>
        </div>
        <div className='footer-bottom'>
            {/* <div className='store'>
                <a href="https://play.google.com/store/apps/details?id=com.ummys.customer">
                        <FaGooglePlay size={20} style={{color: '#d3d3d3', marginRight: '10px'}} />
                </a>
                <a href="https://apps.apple.com/us/app/ummys-customer/id1662079055">
                        <FaAppStore size={24} style={{color: '#d3d3d3', marginRight: '10px'}} />
                </a>
            </div> */}
            <div className='content'>
                <div className='rights'>
                    <p>&copy; Ummys, Inc. All Rights Reserved.</p>
                </div>
                <div>
                    <a href="https://www.youtube.com/@ummysapp/">
                        <FaYoutube size={20} style={{color: '#d3d3d3', marginRight: '10px'}} />
                    </a>
                    <a href="https://www.facebook.com/Ummysapp/">
                        <FaFacebook size={20} style={{color: '#d3d3d3', marginRight: '10px'}} />
                    </a>
                    <a href="https://www.instagram.com/ummysapp/">
                        <FaInstagram size={20} style={{color: '#d3d3d3', marginRight: '10px'}} />
                    </a>
                    <a href="https://www.tiktok.com/@ummysfood">
                        <FaTiktok size={20} style={{color: '#d3d3d3', marginRight: '10px'}} />
                    </a>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer