import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import ChefInfo from '../components/Testimonials'

const Chefs = () => {
    return (
      <>
        <Navbar />
        <ChefInfo />
        <Footer />
      </>
    )
  }
  
  export default Chefs