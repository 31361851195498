import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import Logo from '../asset/logo-2024.png'
import {FaBars, FaTimes} from 'react-icons/fa'
import './NavbarStyles.css'

const Navbar = () => {
    const[click, setClick] = useState(false)
    const handleClick = () => setClick(!click)

  return (
    <header>
        <nav className='navbar'>
            <div className='logo'>
                <Link to='/'><img src={Logo} style={{ width: "35%" }} alt='' /></Link>
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                <li className='nav-item'>
                    <Link to='/' className='nav-link'>Home</Link>
                </li>
                <li className='nav-item'>
                    <Link to='/ourstory' className='nav-link'>Our Story</Link>
                </li>
                <li className='nav-item'>
                    <Link to='/chef' className='nav-link'>Become A Chef</Link>
                </li>
                <li className='nav-item'>
                    <Link to='/foodsafety' className='nav-link'>Food Safety</Link>
                </li>
            </ul>
            <div className='hamburger' onClick={handleClick}>
                {click ? (<FaTimes size={20} color='white' />) : (<FaBars size={20} />)}
                
            </div>
        </nav>
    </header>
  )
}

export default Navbar