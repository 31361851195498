import React from 'react'
import './OurStoryStyles.css'

const OurStory = () => {
  return (
    <div className='pricing'>
        <div className='container'>
            <div className='top-content'>
                <div>
                    <h2> A taste of home at your <span>Fingertips!</span></h2>
                    <h3>To help bring home cooked meals to everyone's table.</h3>
                    <p>Discover a world of delectable flavors, authentic recipes, and the comforting warmth of home-cooked meals. 
                        Our platform connects passionate home cooks with food lovers like you, offering a unique culinary experience unlike any other.
                        Why settle for generic takeout or fast food when you can savor the lovingly prepared dishes of talented home chefs? 
                        Each meal is crafted with care, using fresh ingredients, secret family recipes, and a dash of personal touch. 
                        From traditional favorites to global delights, our diverse menu will tantalize your taste buds and transport you to a world of homemade goodness.
                    </p>
                </div>
            </div>
            <div className='bottom-content'>
                <div className='card'>
                    <h3>Here's why you'll love Ummys</h3>
                </div>
                <div className='card'>
                    <div>
                        <h5>1. Unforgettable Home-Cooked Flavors: </h5>
                        <p> Say goodbye to bland and predictable meals. 
                            Our talented home cooks infuse each dish with authentic flavors and culinary expertise, 
                            giving you a truly unforgettable dining experience.
                        </p>
                    </div>
                    <div>
                        <h5>2. Variety and Choice: </h5>
                        <p>Explore a vast selection of cuisines and dishes, tailored to your preferences. 
                            Whether you crave comfort food, exotic spices, or healthy alternatives, our menu has something to 
                            satisfy every palate.
                        </p>
                    </div>
                    <div>
                        <h5>3. Convenience Redefined: </h5>
                        <p>Ordering home-cooked meals has never been easier. Simply browse our enticing menu, 
                            customize your options, and place your order with a few clicks. Enjoy the convenience of delivery to your 
                            doorstep or opt for easy pickup.
                        </p>
                    </div>
                    <div>
                        <h5>4. Community and Connection: </h5>
                        <p>Ummys is more than just a food platform; it's a community of 
                            passionate cooks and food enthusiasts. Share stories, discover culinary tips, and connect with like-minded 
                            individuals who appreciate the art of home cooking.
                        </p>
                    </div>
                    <div>
                        <h5>5. Trust and Quality Assurance: </h5>
                        <p>We handpick our home cooks, ensuring they meet our stringent quality standards. 
                            Rest assured that every dish is prepared in a clean and hygienic environment, with utmost care for your well-being.
                        </p>
                    </div>
                    
                </div>
            </div>
        </div> 
    </div>
  )

}


export default OurStory