import React from 'react'
import './HeroStyles.css'
import heroimage from '../asset/ummy.png'
import {FaGooglePlay, FaAppStore } from 'react-icons/fa'

const Hero = () => {
  return (
    <div className='hero'>
        <div className='container'>
            <div className='content'>
                <div></div>
                <div className='col-1'>
                    <h1>Welcome to Ummys!</h1>
                    <p>
                        Where Home Cooking Comes to You!
                    </p>
                    <div className='store'>
                        <a href="https://play.google.com/store/apps/details?id=com.ummys.customer">
                        <FaGooglePlay size={24} style={{color: '#BF4D29', marginRight: '30px'}} />
                        </a>
                        <a href="https://apps.apple.com/us/app/ummys-customer/id1662079055">
                        <FaAppStore size={26} style={{color: '#BF4D29', marginRight: '10px'}} />
                        </a>
                    </div>
                </div>
                <div></div>
            </div>
        </div>
    </div>
  )
}

export default Hero