import React from 'react'
import './FaqStyles.css'

const Faq = () => {
  return (
    <div className='section-faq'>
        <div className='container'>
            <div className='col-1'>
                <h3><span>Food Safety:</span></h3>
                <p>At <b>Ummys</b>, we prioritize the safety and quality of our customers' dining experience. 
                    That's why we ensure that all our chefs meet rigorous standards in food safety and compliance. 
                    Here's how we maintain a high level of safety:
                </p>
            </div>
            <div className='col-2'>
                <div className='faq'>
                    <h5>Food Safety Certification:</h5>
                    <p>Every Ummys chef is required to pass an ANSI-accredited food safety certification exam. 
                        This certification ensures that our chefs are well-versed in essential food safety practices, 
                        including proper handling, storage, and hygiene.
                    </p>
                </div>
                <div className='faq'>
                    <h5>Food Quality Assessment:</h5>
                    <p>In addition to the certification, our chefs undergo a thorough food quality assessment. 
                        This assessment evaluates their knowledge of ingredient sourcing, cooking techniques, 
                        and overall adherence to Ummys' quality standards. We believe in delivering exceptional quality 
                        to our customers.
                    </p>
                </div>
                <div className='faq'>
                    <h5>Compliance with State and Local Laws:</h5>
                    <p>Ummys chefs are committed to following all state and local laws and regulations regarding home cooking. 
                        This includes obtaining any necessary permits or licenses required to operate a home-based food business. 
                        We prioritize compliance to ensure the safety and legality of our operations.
                    </p>
                </div>
                <div className='faq'>
                    <p><b>Ummys</b> chefs are committed to following all state and local laws and regulations regarding home cooking. 
                        This includes obtaining any necessary permits or licenses required to operate a home-based food business. 
                        We prioritize compliance to ensure the safety and legality of our operations. At Ummys, we believe that great 
                        food begins with a strong foundation of safety and quality.
                    </p>
                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default Faq