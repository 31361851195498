import React from 'react'
import Faq from './Faq'
import Footer from './Footer'
import Hero from './Hero'
import './HomeStyles.css'
import Navbar from './Navbar'
import Pricing from './OurStory'
// import Contact from './Contact'
// import Privacy from './Privacy'
// import Signup from './Signup'
import Testimonials from './Testimonials'

const Home = () => {
  return (
    <div>
        
      {/*
      <Signup />
      */}
      
      <Navbar />
      <Hero />
      <Pricing />
      <Testimonials />
      <Faq />
      {/* <Contact /> */}
      <Footer />
      
      
      {/* <Privacy /> */}
      
    </div>
  )
}

export default Home