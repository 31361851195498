import React from 'react'
import {FaDrumstickBite} from 'react-icons/fa'
import './TestimonialsStyles.css'

const Testimonials = () => {
  return (
    <div className='testimonial'>
        <div className='container'>
            <div className='outline'>
                <div className='content'>
                    <div className='content-info'>
                        <div className='steps'>
                            <div className='col-1'>
                                {<h3>How to become a chef:</h3> }
                                <p className='body'>
                                Are you a talented home cook with a passion for sharing your culinary creations? Join our vibrant community of 
                                chefs and embark on a rewarding journey of delighting food lovers with your home-cooked masterpieces. 
                                Becoming a chef on <b>Ummys</b> is quick, easy, and rewarding.
                                </p>
                            </div>
                            <div className='col-2'>
                                <div className='name'>
                                    <h5>Step 1: Register on the Ummys App</h5>
                                    <p>The first step to becoming a chef is to download the <b>Ummys</b> app and create your account. 
                                        Simply visit the App Store or Google Play Store, search for <b>Ummys</b>, and follow the easy registration process. 
                                        Provide your details and create a profile that showcases your culinary skills and specialties.
                                    </p>
                                    <p></p>
                                    <h5>Step 2: Complete the Food Safety Test</h5>
                                    <p>At <b>Ummys</b>, we prioritize the safety and well-being of our customers. As a chef, you'll need to complete a 
                                        food safety test to ensure compliance with hygiene and sanitation standards. The test covers essential topics such as 
                                        proper food handling, storage, and hygiene practices. Rest assured, we're here to support you throughout the process, 
                                        providing resources and guidelines to help you succeed.
                                    </p>
                                    <p></p>
                                    <h5>Step 3: Set Up Your Menu of Food Items</h5>
                                    <p>Once you've completed the food safety test and passed, it's time to showcase your culinary creations! Access your chef dashboard on the 
                                        Ummys app and set up your menu of food items. Get creative and highlight your signature dishes, family recipes, or specialty cuisines. 
                                        Include detailed descriptions, captivating photos, and any dietary information to entice customers and make their mouths water.
                                    </p>
                                    <p></p>
                                    <h5>Step 4: Set Your Hours of Operations</h5>
                                    <p>Flexibility is a key advantage of being a chef on Ummys. You have the freedom to choose your own working hours. 
                                        Decide when you want to offer your delicious home-cooked meals to customers. Morning, noon, or night – it's up to you. 
                                        You can update your availability and operating hours through the app, ensuring that you cook when it suits you best.
                                    </p>
                                    <p></p>
                                    <h5>Step 5: Start Cooking and Delighting Customers</h5>
                                    <p>With your menu set and hours of operation defined, it's time to put your culinary skills to work. Prepare your dishes with love, 
                                        care, and attention to detail. Customers will place orders through the <b>Ummys</b> app, and you'll receive 
                                        notifications when an order is placed. Follow your recipes, use fresh ingredients, and bring the flavors of home-cooked goodness 
                                        to life.
                                    </p>
                                    <p>Join our community of talented chefs today and embark on a fulfilling journey of sharing your passion for home cooking with food 
                                        lovers in your area. We provide you with the platform, tools, and support you need to succeed. Register now, complete the food
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Testimonials