import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import ContactForm from '../components/Contact'

const Contact = () => {
  return (
    <>
      <Navbar />
      <ContactForm />
      <Footer />
    </>
  )
}

export default Contact